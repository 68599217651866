import { Result } from "antd";
import React from "react";
import styled from "styled-components";

const Error = () => {
  return (
    <Container>
      <Result
        status="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export default Error;
