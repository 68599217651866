// App.js
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";
import { GlobalStyles } from "./style/global";
import DmsStore from "./redux/store";
import { Loader, setStore } from "@bjsdistribution/dms-component-library";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    // For global loader send store to component lib on interceptor needed dispatch loader on request and response
    setStore(DmsStore);
  }, []);

  return (
    <>
      <Provider store={DmsStore}>
        <Loader />
        <GlobalStyles />
        <AppRouter />
      </Provider>
    </>
  );
}

export default App;
