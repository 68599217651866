import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: any;
}

const StyledIconBoxes = styled.svg`
  & .path {
    opacity: 0.4;
    transition: opacity 0.3s ease;
  }
`;
const Offshore = ({ className }: Props): JSX.Element => {
  return (
    <StyledIconBoxes
      className={`icon-boxes ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 16 20"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
<path fillRule="evenodd" clipRule="evenodd" d="M3.416 1C3.1407 1.63079 2.99906 2.31176 3 3C3 3.53044 3.21071 4.03914 3.58579 4.41422C3.96086 4.78929 4.46957 5 5 5H11C11.5304 5 12.0391 4.78929 12.4142 4.41422C12.7893 4.03914 13 3.53044 13 3C13 2.289 12.852 1.612 12.584 1H14C14.5304 1 15.0391 1.21072 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V18C16 18.5304 15.7893 19.0391 15.4142 19.4142C15.0391 19.7893 14.5304 20 14 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21072 1.46957 1 2 1H3.416ZM8 12H5C4.73478 12 4.48043 12.1054 4.29289 12.2929C4.10536 12.4804 4 12.7348 4 13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H8C8.26522 14 8.51957 13.8946 8.70711 13.7071C8.89464 13.5196 9 13.2652 9 13C9 12.7348 8.89464 12.4804 8.70711 12.2929C8.51957 12.1054 8.26522 12 8 12ZM11 8H5C4.74512 8.00029 4.49997 8.09788 4.31463 8.27285C4.1293 8.44782 4.01776 8.68696 4.00283 8.9414C3.98789 9.19584 4.07067 9.44638 4.23426 9.64184C4.39785 9.83729 4.6299 9.9629 4.883 9.993L5 10H11C11.2652 10 11.5196 9.89465 11.7071 9.70711C11.8946 9.51957 12 9.26522 12 9C12 8.73479 11.8946 8.48043 11.7071 8.2929C11.5196 8.10536 11.2652 8 11 8ZM8 3.24631e-06C8.42218 -0.000618833 8.8397 0.088171 9.2251 0.26053C9.61049 0.432888 9.95502 0.684908 10.236 1C10.664 1.478 10.94 2.093 10.991 2.772L11 3H5C5 2.275 5.257 1.61 5.685 1.092L5.764 1C6.314 0.386003 7.112 3.24631e-06 8 3.24631e-06Z" fill="#aea9a1"/>

    </StyledIconBoxes>
  );
};

export { Offshore };
