// App.js
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";
import { GlobalStyles } from "./style/global";
import DmsStore from "./redux/store";
import { Loader } from "@bjsdistribution/dms-component-library";
function App() {
  return (
    <>
      <Provider store={DmsStore}>
        <Loader />
        <GlobalStyles />
        <AppRouter />
      </Provider>
    </>
  );
}

export default App;
