import React from 'react';
import styled from 'styled-components';

interface Props {
  className: unknown;
}

const StyledIconCaretDown2 = styled.svg`
  .g {
    opacity: 0.8;
  }

  .rect {
    // fill: white;
  }

  .path {
    clipRule: evenodd;
    fill: #000000;
    fillRule: evenodd;
    opacity: 0.4;
  }
`;

export const Profile = ({ className }: Props): JSX.Element => {
  return (
    <StyledIconCaretDown2
      className={`icon-caret-down-2 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.8">
        <rect className="rect" height="22" width="22" />
        <path
          className="path"
          clipRule="evenodd"
          d="M6.13345 9.06692C6.2572 8.94332 6.42495 8.8739 6.59985 8.8739C6.77475 8.8739 6.9425 8.94332 7.06625 9.06692L10.9998 13.0005L14.9334 9.06692C15.0586 8.95034 15.224 8.88687 15.395 8.88989C15.566 8.8929 15.7292 8.96217 15.8501 9.08309C15.971 9.20402 16.0403 9.36716 16.0433 9.53814C16.0463 9.70913 15.9828 9.87461 15.8662 9.99972L11.4662 14.3997C11.3425 14.5233 11.1747 14.5927 10.9998 14.5927C10.8249 14.5927 10.6572 14.5233 10.5334 14.3997L6.13345 9.99972C6.00985 9.87597 5.94043 9.70822 5.94043 9.53332C5.94043 9.35842 6.00985 9.19067 6.13345 9.06692Z"
          fill="#3A3630"
          fillRule="evenodd"
          opacity="0.4"
        />
      </g>
    </StyledIconCaretDown2>
  );
};
