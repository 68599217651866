import { Label, AntSelect } from "@bjsdistribution/dms-component-library";
import React from "react";
import styled from "styled-components";

function AssigneeDropdown() {
  const handleSelectChange = () => {};
  return (
    <FormContainer>
      <Label label="Assigned To" required htmlFor="assigneDropdown" />
      <StyledAntSelect
        options={[]}
        onChange={handleSelectChange}
        selectWrapperClass={"rounded"}
        style={{ display: "flex" }}
      />
    </FormContainer>
  );
}
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  width: 285px;
`;
const StyledAntSelect = styled(AntSelect)``;
export default AssigneeDropdown;
