import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import Reminder from "./layout/Reminder";
import { Header } from "./layout";
import Login from "./Login";
import Aside from "./common/menu/Aside";
import Error from "./Error/Error";
import "react-multi-carousel/lib/styles.css";
import "tippy.js/dist/tippy.css";
import { FileExport } from "@bjsdistribution/dms-export";
import EventDashboard from "@bjsdistribution/dms-event-dashboard";
import { CustomerConfigComponent } from "@bjsdistribution/dms-customer-config";
const ProductInformation = React.lazy(
  () => import("@bjsdistribution/dms-product-information")
);
const Debrief = React.lazy(() => import("@bjsdistribution/dms-finance"));
const RouteModule = React.lazy(() => import("@bjsdistribution/dms-routes"));
const Order = React.lazy(() => import("@bjsdistribution/dms-orders"));
const Flags = React.lazy(() => import("@bjsdistribution/dms-flags"));
const InvoiceModule = React.lazy(() => import("@bjsdistribution/dms-invoice"));
const OffShoreModule = React.lazy(
  () => import("@bjsdistribution/dms-offshore")
);
const ReturnClient = React.lazy(
  () => import("@bjsdistribution/dms-client-return")
);

const AppRouter: React.FC = () => {
  const [isLogin, setIsLogin] = useState<boolean>(() => {
    return !!localStorage.getItem("username");
  });
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleSidebar = () => setIsExpanded(!isExpanded);

  // PrivateRoute Component
  const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();

    return isLogin ? (
      children
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  };

  // Protected Layout Component
  const ProtectedLayout: React.FC = () => (
    <div className="coverDms">
      <Reminder />
      <MainContainer>
        <div className="dashboard">
          <Header />
          <div className="placeHolderWrapper">
            <Aside toggleSidebar={toggleSidebar} />
            <div className="placeHolder">
              <Suspense fallback={<LoadingMessage />}>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/product" replace />}
                  />
                  <Route path="/product/*" element={<ProductInformation />} />
                  <Route path="/finance/*" element={<Debrief />} />
                  <Route path="/routes/*" element={<RouteModule />} />
                  <Route path="/orders/*" element={<Order />} />
                  <Route path="/flag/*" element={<Flags />} />
                  <Route
                    path="/finance/invoice/*"
                    element={<InvoiceModule />}
                  />
                  <Route path="/offshore/*" element={<OffShoreModule />} />
                  <Route
                    path="/orders/view/return/*"
                    element={<ReturnClient />}
                  />
                  <Route path="/file-export" element={<FileExport />} />
                  <Route
                    path="/event-dashboard/*"
                    element={<EventDashboard />}
                  />
                  <Route
                    path="/customer-config/*"
                    element={<CustomerConfigComponent />}
                  />
                  <Route path="*" element={<Error />} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );

  return (
    <Router>
      <Routes>
        {/* Login Route */}
        <Route
          path="/login"
          element={
            isLogin ? (
              <Navigate to="/routes" />
            ) : (
              <Login setIsLogin={setIsLogin} />
            )
          }
        />
        {/* Protected Routes */}
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <ProtectedLayout />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

const MainContainer = styled.div`
  .dashboard {
    flex: 1;
    transition: all ease 0.5s;

    .placeHolder {
      padding: 72px 20px 10px 20px;
    }
  }

  .placeHolderWrapper {
    width: 100%;
    padding-left: 63px;
    transition: all ease 0.5s;

    &.expanded {
      padding-left: 61px;
    }
  }
`;

const LoadingMessage: React.FC = () => (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
    <div className="spinner" />
    <p>Loading, please wait...</p>
  </div>
);

export default AppRouter;
