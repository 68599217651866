import { createSlice } from '@reduxjs/toolkit';

export interface UiRenderType {
}
const initialState: UiRenderType = {
  
};

export const appManagerUiRenderSlice = createSlice({
  name: 'appManagerUiRenderSlice',
  initialState,
  reducers: {
   

   
  },
});

export const {
  
} = appManagerUiRenderSlice.actions;

export default appManagerUiRenderSlice.reducer;
